<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="reportSetting-container">
        <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
        <div class="header-container">
            <div>已选字段</div>
            <div>
                <el-button class="common-screen-btn" @click="() => { $router.go(-1) }" plain>返回</el-button>
                <el-button class="common-screen-btn" @click="() => { saveVisible = true }" type="primary">保存</el-button>
            </div>
        </div>
        <div class="choose-field-container">
            <div class="choose-field">
                <draggable v-model="detailInfo" @update="datadragEnd" :options="{animation:200}">
                    <div class="choose-field-item" v-for="(item, index) in detailInfo" :key="index">{{item.fieldName}}<i @click="() => { chooseDelete(item) }" class="el-icon-circle-close"></i></div>
                </draggable>
            </div>
            <div class="field-checkbox-container">
                <div class="field-left">
                    <div class="field-title">功能板块</div>
                    <div class="" @click="() => { activeTemplate(key) }" :class="{'left-active-item': key == itemName, 'left-item': true}" v-for="(item, key) in TemplateInfo" :key="key">{{key}}</div>
                </div>
                <div class="field-center">
                    <div class="field-title">列表相关字段</div>
                    <div class="center-item" v-for="(item, index) in TemplateInfo[itemName]" :key="index">
                        <el-checkbox @change="(val) => checkBoxFun(val, item)" v-model="item.expandThree">{{item.dataName}}</el-checkbox>
                        <el-tooltip v-if="item.expandOne" effect="dark" :content="'【说明】：' + item.expandOne" placement="bottom" enterable>
                            <img src="../../assets/images/question.png" class="tit-img"/>
                        </el-tooltip>
                    </div>
                </div>
                <div class="field-right">
                    <div class="field-title">来源</div>
                    <div class="right-item" v-for="(item, index) in TemplateInfo[itemName]" :key="index">{{item.remark}}</div>
                </div>
            </div>
        </div>
        <el-dialog v-loading="loading" element-loading-text="数据较多，拼命加载中..." v-dialogDrag title="保存设置" class="common-dialog-isOk" :append-to-body="true" width="400px" :visible.sync="saveVisible">
        <div>确认保存当前设置吗？</div>
        <span slot="footer" class="dialog-footer">
            <el-button class="common-screen-btn" @click="saveVisible = false">取 消</el-button>
            <el-button class="common-screen-btn" type="primary" @click="() => { reportSetAddEdit() }">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { reportSetDetail, reportSetTemplate, reportSetAddEdit } from "../../service/report.js"
import draggable from 'vuedraggable'
export default {
    components: {
        Breadcrumb,
        draggable
    },
    data() {
        return {
            loading: false,
            BreadcrumbData: [
                { title: "首页", isLink: true, url: "/index" },
                { title: "数据", isLink: false },
                { title: "自定义报表", isLink: true, url: '/custom-report/report' },
                { title: this.$route.query.backName, isLink: true, url: this.$route.query.backUrl, },
                { title: "报表设置", isLink: false },
            ],
            recordId: this.$route.params.id,
            detailInfo: [],
            TemplateInfo: [], // 配置模板
            itemName: '', // 选中的模板
            saveVisible: false,
            uuid: this.$route.query.elementUuid
        }
    },
    created() {
        this.reportSetDetail()
        if (this.$route.query.backName && this.$route.query.backUrl) {
            this.BreadcrumbData = [
                { title: "首页", isLink: true, url: "/index" },
                { title: "数据", isLink: false },
                { title: "自定义报表", isLink: true, url: '/custom-report/report' },
                { title: this.$route.query.backName, isLink: true, url: `${this.$route.query.backUrl}?reportName=${this.$route.query.backName}&elementUuid=${this.$route.query.elementUuid}&recordType=${this.$route.query.recordType}`, },
                { title: "报表设置", isLink: false },
            ]
        } else {
            this.BreadcrumbData = [
                { title: "首页", isLink: true, url: "/index" },
                { title: "数据", isLink: false },
                { title: "自定义报表", isLink: true, url: '/custom-report/report' },
                { title: "报表设置", isLink: false },
            ]
        }
        // this.reportSetTemplate()
    },
    methods: {
        async reportSetAddEdit() { // 设置报表-保存
            let detailInfo = [...this.detailInfo]
            detailInfo.map((item, index) => {
                item.sort = index
            })
            let { code } = await reportSetAddEdit(detailInfo)
            if(code == 200) {
                this.$message.success('保存成功')
                this.$router.push(`/custom-report/report-detail/${this.$route.params.id}?reportName=${this.$route.query.backName}&elementUuid=${this.$route.query.elementUuid}&recordType=${this.$route.query.recordType}`)
                // this.saveVisible = false
            }
        },
        chooseDelete(item) { // 删除选中
            let detailInfo = [...this.detailInfo]
            let TemplateInfo = {...this.TemplateInfo}
            let itemName = this.itemName
            detailInfo = detailInfo.filter((itemTwo) => {
                return itemTwo.fieldName != item.fieldName
            })
            TemplateInfo[itemName].map((itemTwo) => {
                if (itemTwo.dataName == item.fieldName) {
                    itemTwo.expandThree = false
                    return
                }
            })
            this.detailInfo = detailInfo
            this.TemplateInfo = TemplateInfo
        },
        checkBoxFun(val, item) { // checkbox值改变时
            let detailInfo = [...this.detailInfo]
            let recordId = this.recordId
            let timeType // 字段时间查询类型显示  1：日月年都显示 2只显示日 3只显示月 4只显示年
            if (item.expandFour == 'day') {
                timeType = 2
            } else if (item.expandFour == 'month') {
                timeType = 3
            } else if (item.expandFour == 'year') {
                timeType = 4
            } else {
                timeType = 1
            }
            if(val) {
                detailInfo.push({recordId, fieldUuid: item.elementUuid, fieldName: item.dataName, fieldValue: item.dataValue, timeType})
            } else {
                detailInfo = detailInfo.filter((itemTwo) => {
                    return itemTwo.fieldName != item.dataName
                })
            }
            this.detailInfo = detailInfo
        },
        datadragEnd(evt) { // 排序
            evt.preventDefault();
            // console.log('拖动前的索引 :' + evt.oldIndex)
            // console.log('拖动后的索引 :' + evt.newIndex)
            // let detailInfo = [...this.detailInfo]
            // console.log(detailInfo)
            // let newSort = detailInfo[evt.oldIndex]

            // detailInfo[evt.oldIndex] = detailInfo[evt.newIndex]
            // detailInfo[evt.newIndex] = newSort

            // this.detailInfo = detailInfo
        }, 
        activeTemplate(name) { // 选中模板
            this.itemName = name
        },
        async reportSetDetail() {  // 获取详情
            this.loading = true
            let { data } = await reportSetDetail({recordId: this.recordId})
            if(data && data.length) {
                this.detailInfo = data
                this.reportSetTemplate(true)
            } else {
                this.detailInfo = []
                this.reportSetTemplate(false)
            }
        },
        async reportSetTemplate(exist) {  // 获取配置模板
            let { data } = await reportSetTemplate({uuid: this.uuid})
            let i = 0 // 用于取第一条数据的键名
            let recordId = this.recordId
            let detailInfo = [...this.detailInfo]
            for(let key in data) {
                data[key].map((item) => {
                    if (!exist) { // 新增时，默认设置为空
                        if(item.expandThree == 0) { 
                            item.expandThree = false 
                        } else { 
                            let timeType // 字段时间查询类型显示  1：日月年都显示 2只显示日 3只显示月 4只显示年
                            if (item.expandFour == 'day') {
                                timeType = 2
                            } else if (item.expandFour == 'month') {
                                timeType = 3
                            } else if (item.expandFour == 'year') {
                                timeType = 4
                            } else {
                                timeType = 1
                            }
                            detailInfo.push({recordId, fieldUuid: item.elementUuid, fieldName: item.dataName, fieldValue: item.dataValue, timeType})
                            item.expandThree = true 
                        }
                    } else {
                        item.expandThree = false
                        let timeType // 字段时间查询类型显示  1：日月年都显示 2只显示日 3只显示月 4只显示年
                        if (item.expandFour == 'day') {
                            timeType = 2
                        } else if (item.expandFour == 'month') {
                            timeType = 3
                        } else if (item.expandFour == 'year') {
                            timeType = 4
                        } else {
                            timeType = 1
                        }
                        detailInfo.map((itemTwo) => {
                            if (item.dataName == itemTwo.fieldName) {
                                item.expandThree = true
                                itemTwo.timeType = timeType
                            }
                        })
                    }
                })
                if(i == 0) { this.itemName = key }
                i++
            }
            this.detailInfo = detailInfo
            this.TemplateInfo = data
            this.loading = false
        }
    }
}
</script>
<style lang="less" scoped>
.reportSetting-container {
    padding: 32px;
    background: #fff;
    text-align: left;
     .tit-img {
        margin-left: 8px;
        cursor: pointer;
    }
    .choose-field-container {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        .field-checkbox-container {
            display: flex;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            width: 100%;
            box-sizing: border-box;
            margin-top: 32px;
            .field-title {
                height: 64px;
                line-height: 64px;
                background: #f0f0f0;
                color: #333;
                font-size: 14px;
                padding-left: 52px;
            }
            .field-left {
                width: 204px;
                border-right: #f0f0f0 1px solid;
                .left-item {
                    height: 40px;
                    line-height: 40px;
                    padding-left: 52px;
                    color: #333;
                    background: #fff;
                    cursor: pointer;
                }
                .left-active-item {
                    color: #fff;
                    background: #1890ff;
                }
            }
            .field-center {
                flex: 1;
                .center-item {
                    height: 40px;
                    line-height: 40px;
                    padding-left: 52px;
                    color: #333;
                    background: #fff;
                    display: flex;
                    align-items: center;
                }
            }
            .field-right {
                width: 644px;
                .right-item {
                    height: 40px;
                    line-height: 40px;
                    padding-left: 52px;
                    color: #333;
                    background: #fff;
                }
            }
        }
        .choose-field {
            width: 100%;
            height: 222px;
            border: 1px #D9D9D9 solid;
            border-radius: 4px;
            padding: 16px 0 16px 16px;
            box-sizing: border-box;
            overflow-y: scroll;     
            .choose-field-item {
                color: #333;
                font-size: 14px;
                padding: 6px 10px 6px 16px;
                line-height: 1;
                border: 1px solid #bbb;
                border-radius: 4px;
                display: inline-block;
                margin: 0 16px 16px 0;
                cursor: pointer;
                .el-icon-circle-close {
                    font-size: 16px;
                    margin-left: 12px;
                    cursor: pointer;
                    color: #999;
                }
            }
        }
    }
    .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 16px;
        .common-screen-btn {
            margin-right: 16px;
        }
    }
}
</style>